import React from 'react';

import PageLayout from '../layouts/page/page.layout';
import GetStartedContent from '../components/get-started-content/get-started-content.comp';
import { useIsMobile } from '../utils/hooks/use-is-mobile';
import i18next from '../components/i18n';

const GetStartedPage = ({location}) => {
	const isMobile = useIsMobile();
	return (
		<PageLayout
			title='Get started'
			subTopic={
				isMobile
					? 'Guided Face Placement'
					: i18next.t('get_started.subtitle')
			}
			location={location}
		>

			<GetStartedContent />
		</PageLayout>
	);
};

export default GetStartedPage;
