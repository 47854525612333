import React from 'react';

import { StyledIconFrame } from './icon-frame.styled';

export default function IconFrame({ icon, title }) {
	return (
		<StyledIconFrame>
			<a>
			  <div className='icon-container'>
				<img src={icon} alt='' />
			  </div>
			</a>
			<span>{title}</span>
		</StyledIconFrame>
	);
}