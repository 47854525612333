
import i18next from '../components/i18n'

export const list = [
	{
		id: 2,
		text: i18next.t('get_started.first'),
	},

	{
		id: 3,
		text: i18next.t('get_started.second'),
	},

	{
		id: 4,
		text: i18next.t('get_started.third'),
	},
];
